@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

h1 {
  font-size: 28px;
  font-family: "Open Sans", sans-serif;
  color: #182025;
  margin: 0;
  font-weight: 600;
}

.font20 {
  font-size: 20px;
  font-weight: 400;
}

.font20Bold {
  font-size: 20px;
  font-weight: 600;
}

.font16 {
  font-size: 16px;
  font-weight: 400;
}

.font16Bold {
  font-size: 16px;
  font-weight: 600;
}

.subhead {
  font-size: 14px;
  color: rgba(144, 144, 144, 1);
}

span,
div,
h* {
  font-family: "Open Sans", sans-serif !important;
}

.activeCategoryLink {
  color: #B51441;
  font-weight: 600;
}

.MuiTypography-h5 {
  font-size: 20px !important;
  font-family: "Open Sans", sans-serif !important;
  color: #182025 !important;
  font-weight: 600 !important;
}

.footer-text {
  text-align: center;
  padding: 10px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
  border-top: 1px solid #ccc;
  background: #fff;
}

.mobileView {
  display: none;
}

@media screen and (max-width: 812px) {
  .mobileView {
    display: block;
  }
  .webView {
    display: none;
  }
}