.continueButton {
  border-radius: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 15px !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
}

.backgroudImage {
  background-size: 50%;
  background-position: center center;
  background-color: #ccc;
  background-repeat: repeat;
  min-height: calc(100vh - 40px);
}

.restaurantBackgroundImage {
  background-size: cover;
  background-repeat: none;
  background-position: center center;
  background-color: #ccc;
  min-height: calc(100vh - 40px);
}

.mainSection {
  width: 910px !important;
  max-width: 90vw;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  border: 1px solid #ccc;
}

.padding30 {
  padding-left: 30px;
  padding-right: 30px;
}

.topSection {
  padding: 30px 30px 15px 30px;
}

.logo {
  width: 300px;
  max-width: 50vw;
}

.successMessage {
  text-align: left;
  margin-top: 20px;
  font-size: 18px;
  color: grey;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
}

.errorMessage {
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
  color: grey;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.reserveTableSignup {
  margin-top: 15px;
  margin-bottom: 15px;
}

.loginModalStyle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  max-width: 500px;
  width: 90%;
  overflow-y: scroll;
  max-height: 80vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
  border-radius: 8px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.loginModalStyle::-webkit-scrollbar {
  display: none;
}

.infoMessage {
  background: rgb(215, 234, 240);
  padding: 10px;
  border: 1px solid rgb(215, 234, 240);
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.warningMessage {
  background: #ffcdd2;
  padding: 10px;
  border: 1px solid rgb(215, 234, 240);
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.tab {
  padding-top: 15px;
  padding-bottom: 15px;
}

.warningMessageAvailability {
  margin-top: 10px;
  color: #b51441;
}

.userIcon {
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 50px;
  color: rgba(0, 0, 0, 0.54);
  padding: 10px;
}

@media (max-width: 600px) {
  .topSection {
    padding: 30px 15px 15px 15px;
  }
  .tab {
    font-size: 12px;
  }

  .mainSection {
    width: 910px !important;
    max-width: 100vw;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 10px;
    border: 1px solid #ccc;
  }
}

@media (max-width: 812px) {
  .allergyWarningStyle {
    margin-top: 15px;
    background-color: #fbe9e7 !important;
    margin-bottom: 15px;
    font-size: 14px;
    width: 100%;
  }
}

@media (min-width: 813px) {
  .allergyWarningStyle {
    margin-top: 15px;
    background-color: #fbe9e7 !important;
    margin-bottom: 15px;
    font-size: 14px;
    width: 80%;
  }
}

.messageFromRestaurant {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.specialNote {
  margin: 15px 15px 15px 0px !important;
  width: 300px;
  max-width: 90%;
}

.addressLink:link {
  color: #000;
  text-decoration: none;
}

.addressLink:visited {
  color: #000;
  text-decoration: none;
}

/* mouse over link */
.addressLink:hover {
  color: #000;
  text-decoration: none;
}

/* selected link */
.addressLink:active {
  color: #000;
  text-decoration: none;
}

.cardRoot {
  width: 100%;
}

.cardContentRoot {
  padding: 0px !important;
}

.cardTitle {
  padding: 16px;
  text-align: center;
  background: red;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.cardContainer {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.basicInfo {
  text-align: center;
}

.largeButton {
  padding: 15px 22px !important;
  font-weight: 600 !important;
}
